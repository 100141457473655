<script setup lang="ts">
const {  t } = useI18n()
</script>

<template>
  <LandingSectionhead>
    <template v-slot:title>{{ $t('contactTitle') }}</template>
    <template v-slot:desc>{{ $t('contactSubTitle') }}</template>
  </LandingSectionhead>

  <div class="grid md:grid-cols-2 gap-10 mx-auto max-w-4xl mt-16">
    <div>
      <h2 class="font-medium text-2xl text-gray-800">
        {{  $t('contactSubTitle') }}
      </h2>
      <p class="text-lg leading-relaxed text-slate-500 mt-3">
        {{  $t('messageBlockSubTitle') }}
      </p>
      <div class="mt-5">
        <div class="flex items-center mt-2 space-x-2 text-gray-600">
          <Icon class="text-gray-400 w-4 h-4" name="uil:map-marker" />
          <span>2030-Érd, Magyarország</span>
        </div>
        <div class="flex items-center mt-2 space-x-2 text-gray-600">
          <Icon class="text-gray-400 w-4 h-4" name="uil:envelope" /><a
            href="mailto:info@flexi-solutions.net"
        >info@flexi-solutions.net</a
        >
        </div>
        <div class="flex items-center mt-2 space-x-2 text-gray-600">
          <Icon class="text-gray-400 w-4 h-4" name="ic:sharp-facebook" /><a
            href="https://www.facebook.com/flexiwebmegoldasok"
        >Facebook</a
        >
        </div>
        <!-- <div class="flex items-center mt-2 space-x-2 text-gray-600">
          <Icon class="text-gray-400 w-4 h-4" name="uil:phone" /><a
            href="tel:+1 (234) 5678 999"
            >+1 (234) 5678 999</a
          >
        </div> -->
      </div>
    </div>
    <div>
      <LandingContactform />

    </div>
  </div>
</template>
